@charset "utf-8";

// =======================
// Module (一番小さい枠組み)
// =======================

// インデント
.m-indent {
  text-indent: 1em;
}

// 黄色マーカー
.m-line {
  background: linear-gradient(transparent 60%, #ff6 60%);
}

// 文字間隔
.m-letter_space {
  letter-spacing: 0.07em;
}

// 星リスト
.list_star {
  position: relative;
  padding: 0 0 10px 1.7em;
  &::before {
    position: absolute;
    top: 4px;
    left: 0;
    content: '';
    background-image: url('../images/star.png');
    background-size: cover;
    width: 1em;
    height: 1em;
  }
  &:last-child {
    padding-bottom: 0;
  }
  a {
    &:link,
    &:visited,
    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
}
// -リスト
.list_hyphen {
  position: relative;
  padding: 0 0 10px 1.7em;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '-';
  }
  a {
    &:link,
    &:visited,
    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
}

// button要素のスタイル解除
button.m-plain-button {
  display: inline-block;
  padding: 0;
  border: none;
  margin: 0;
  background: none;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: $color_secondary;
  }
}

// Flexboxによる複数要素の横並べ
.flex-container {
  display: flex;

  .flex-item {
    flex: 0 0 auto;
    margin: 10px;
  }
}
